<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <header>
          <h3>会員登録</h3>
        </header>
      </v-col>
    </v-row>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<script>
import liff from '@line/liff'
import { API_ENDPOINT } from '@/literals.js'
import { CiApiTool } from '@/module.js'
import Loader from '@/components/_Loader.vue'

export default {
  components: {
    'loader': Loader,
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },
    aToken: {
      type: String,
      required: true,
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      loading: false,
      loadingMessage: null,
      apiTool: new CiApiTool(API_ENDPOINT, this.shopId, this.aToken),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    zeroAddedNo() {
      return no => ('000' + no).slice(-4)
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.createCustomer()
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    createCustomer() {
      this.loading = true
      this.loadingMessage = '会員情報作成中・・・'

      this.apiTool.apiReqCiWithData('POST', 'create/customer/').then( response => {
        liff.sendMessages([
          {
            type: 'text',
            text: '会員番号は ' + this.zeroAddedNo(response.customer_id) + ' です'
          }
        ])
      })
      .catch(() => alert('会員情報作成中にエラーが発生しました\nしばらくしてからもう一度試して下さい') )
      .then(() => {
        this.loading = false
        liff.closeWindow()
      })
    },
  }
}
</script>

<style scoped>
</style>
